import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LabDetails = React.memo(({ labInfo }) => (
  <StyledList>
    {Object.entries(labInfo).map(([key, value]) =>
      value !== null ? (
        <StyledListItem key={key}>
          <StyledKey>{key}</StyledKey>
          <StyledValue>{value.toString()}</StyledValue>
        </StyledListItem>
      ) : null
    )}
  </StyledList>
));

const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
`;

const StyledListItem = styled.li`
  padding: 1rem;
  border-bottom: 1rem solid rgba(248, 248, 250);
  display: flex;
  justify-content: space-between;
`;

const StyledKey = styled.span``;

const StyledValue = styled.span``;

LabDetails.propTypes = {
  labInfo: PropTypes.object.isRequired,
};

LabDetails.defaultProps = {
  labInfo: {
    name: 'NO LAB INFO FOUND',
    locationNumber: '00000',
  },
};

export default LabDetails;
