import React from 'react';
import { TableCell, TableRow, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

const LabTableRow = React.memo(({ locationNumber, name, buttonOnClick }) => (
  <StyledTableRow style={{ borderBottom: '5px solid rgba(248, 248, 250)' }}>
    <StyledTableCell colSpan={4}>{name}</StyledTableCell>
    <StyledTableCell colSpan={4} data-testid="locationNumber">
      {locationNumber}
    </StyledTableCell>
    <StyledTableCell colSpan={4}>
      <StyledButton
        variant="contained"
        onClick={buttonOnClick}
        data-testid={`${locationNumber}DetailsRedirectButton`}
      >
        Full Location Info
      </StyledButton>
    </StyledTableCell>
  </StyledTableRow>
));

const StyledTableRow = styled(TableRow)({
  borderBottom: '5px solid rgba(248, 248, 250)',
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'center',
});

const StyledButton = styled(Button)({
  backgroundColor: 'rgba(50, 130, 255)',
  color: 'white',
});

LabTableRow.propTypes = {
  locationNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  buttonOnClick: PropTypes.func.isRequired,
};

export default LabTableRow;
