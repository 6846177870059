import React from 'react';
import styled from 'styled-components';
import LoadingGif from './Loading.gif';

const ChickenSpinner = React.memo(() => (
  <StyledChickenSpinner
    draggable={false}
    src={LoadingGif}
    alt="Loading Spinner"
  />
));

const StyledChickenSpinner = styled.img`
  width: 100px;
  position: absolute;
  top: 50%;
  right: 50%;
`;

export default ChickenSpinner;
