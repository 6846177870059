import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Loading, H1, P } from 'components/universal';
import LabDetails from 'components/app/LabDetailsPage/LabDetails';
import axios from 'axios';
import Constants from '../../constants';
import flat from 'flat';
import styled from 'styled-components';
import { styled as styledMaterialUI } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

const LabDetailsPage = () => {
  const { locationNumber } = useParams('locationNumber');
  const history = useHistory();
  const isMounted = useRef(true);

  const [isLoading, setIsLoading] = useState(true);
  const [labInfo, setLabInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isMounted.current) {
      const source = axios.CancelToken.source();
      const url = `${Constants.LOCATION_API_QA_URL}/orders/locations/1.0/${locationNumber}`;
      const config = {
        cancelToken: source.token,
      };
      axios
        .get(url, config)
        .then(({ data }) => {
          const flattenedLabInfo = flat.flatten(data);
          setLabInfo(flattenedLabInfo);
        })
        .catch(() => {
          setErrorMessage(
            `There was a problem getting the lab details for ${locationNumber}, please try again`
          );
        })
        .finally(() => {
          setIsLoading(false);
          source.cancel();
        });
    }

    return () => {
      isMounted.current = false;
    };
  }, [locationNumber]);

  const homeButtonOnClick = useCallback(() => {
    history.push('/');
  }, [history]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <Header>
        <H1>Lab Details - {locationNumber}</H1>
      </Header>
      <MainContent>
        <TextContainer>
          <P>{errorMessage}</P>
        </TextContainer>
        <ButtonContainer>
          <StyledButton data-testid="homeButton" onClick={homeButtonOnClick}>
            Back Home
          </StyledButton>
        </ButtonContainer>
        <LabDetailsContainer>
          <LabDetails labInfo={labInfo} />
        </LabDetailsContainer>
      </MainContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgba(74, 92, 105, 100);
`;

const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: rgba(239, 239, 244, 100);
`;

const ButtonContainer = styled.div`
  width: 100%;
  background-color: rgba(239, 239, 244, 100);
`;

const StyledButton = styledMaterialUI(Button)({
  color: 'rgba(50, 130, 255)',
  textAlign: 'left',
  margin: '1rem',
});

const LabDetailsContainer = styled.div`
  width: 100%;
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 1rem;
  background-color: #fff;
`;

export default LabDetailsPage;
