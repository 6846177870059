import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import DashboardPage from 'containers/DashboardPage';
import LabDetailsPage from 'containers/LabDetailsPage';
import Error from 'containers/Error';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route
        exact
        path="/labDetails/:locationNumber"
        component={LabDetailsPage}
      />
      <Route component={Error} />
    </Switch>
  </Router>
);

export default App;
