import React, { useCallback, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
} from '@material-ui/core';
import LabTableRow from './LabTableRow';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';

const DashboardTable = React.memo(
  ({
    rowsPerPage,
    searchedLabs,
    page,
    rowsPerPageOptions,
    onChangePage,
    onChangeRowsPerPage,
  }) => {
    const history = useHistory();
    const emptyRows = useMemo(
      () =>
        rowsPerPage -
        Math.min(rowsPerPage, searchedLabs.length - page * rowsPerPage),
      [page, rowsPerPage, searchedLabs.length]
    );

    const labTableRowRedirectButtonOnClick = useCallback(
      (locationNumber) => {
        history.push(`/labDetails/${locationNumber}`);
      },
      [history]
    );

    return (
      <StyledTable size="medium">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell colSpan={4}>Name</StyledTableCell>
            <StyledTableCell colSpan={4}>Location Number</StyledTableCell>
            <StyledTableCell colSpan={4}></StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? searchedLabs.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : searchedLabs
          ).map(({ locationNumber, name }) => (
            <LabTableRow
              key={locationNumber}
              locationNumber={locationNumber}
              name={name}
              buttonOnClick={() =>
                labTableRowRedirectButtonOnClick(locationNumber)
              }
            />
          ))}

          {emptyRows > 0 && (
            <StyledTableRow
              style={{ height: 53 * emptyRows }}
              data-testid="emptyTableRow"
            >
              <TableCell colSpan={6} />
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              page={page}
              onChangePage={onChangePage}
              count={searchedLabs.length}
              rowsPerPage={rowsPerPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              SelectProps={{ 'data-testid': 'tablePagination' }}
            />
          </TableRow>
        </TableFooter>
      </StyledTable>
    );
  }
);

const StyledTable = styled(Table)({
  width: '100%',
});

const StyledTableRow = styled(TableRow)({
  borderBottom: '5px solid rgba(248, 248, 250)',
});

const StyledTableCell = styled(TableCell)({
  textAlign: 'center',
  backgroundColor: 'rgba(248, 248, 250)',
});

DashboardTable.propTypes = {
  rowsPerPage: PropTypes.number.isRequired,
  searchedLabs: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

DashboardTable.defaultProps = {
  searchedLabs: [],
};

export default DashboardTable;
