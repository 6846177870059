import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Input } from '@material-ui/core';

const TextInput = React.memo(
  styled(Input)({
    margin: '2rem 0.5rem',
    width: '40%',
    borderColor: 'rgba(50, 130, 255)',
  })
);

export default TextInput;
