import React from 'react';
import styled from 'styled-components';
import { H1, P } from 'components/universal';
import { Link } from 'react-router-dom';

const Error = () => (
  <PageContainer>
    <Header>
      <H1>Error</H1>
    </Header>
    <MainContent>
      <P>
        The url you entered doesn't exist, please click{' '}
        <StyledLink to="/">here</StyledLink> to go back to the Home Page!
      </P>
    </MainContent>
  </PageContainer>
);

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)``;

export default Error;
