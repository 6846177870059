import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Paper, TableContainer, Link } from '@material-ui/core';
import { styled as styledMaterialUI } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Constants from '../../constants';
import { H1, Loading, TextInput, P } from 'components/universal';
import DashboardTable from 'components/app/DashboardPage/DashboardTable';
import Fuse from 'fuse.js';

const DashboardPage = () => {
  const isMounted = useRef(true);

  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [originalLabs, setOriginalLabs] = useState([]);
  const [searchedLabs, setSearchedLabs] = useState([]);

  const rowsPerPageOptions = useMemo(
    () => [5, 10, 25, { label: 'All', value: -1 }],
    []
  );

  useEffect(() => {
    if (isMounted.current) {
      const source = axios.CancelToken.source();
      const url = `${Constants.LOCATION_API_QA_URL}/orders/locations/1.2/locations`;
      const config = {
        cancelToken: source.token,
      };

      axios
        .get(url, config)
        .then(({ data }) => {
          setOriginalLabs(data);
          setSearchedLabs(data);
        })
        .catch(() => {
          setErrorMessage(
            'There was a problem getting all location numbers, please reload'
          );
        })
        .finally(() => {
          setIsLoading(false);
          source.cancel();
        });
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  const searchValueOnChangeHandler = useCallback(
    (event) => {
      const options = {
        shouldSort: true,
        keys: ['name', 'locationNumber'],
      };

      const fuse = new Fuse(originalLabs, options);
      const searchResults = fuse
        .search(event.target.value)
        .map(({ item: { locationNumber, name } }) => ({
          locationNumber,
          name,
        }));

      setSearchValue(event.target.value);
      setSearchedLabs(
        event.target.value.length > 0 ? searchResults : originalLabs
      );
      setCurrentPage(0);
      setRowsPerPage(searchResults.length < rowsPerPage ? 5 : rowsPerPage);

      if (searchResults.length === 0 && event.target.value.length > 0) {
        setErrorMessage('No labs found given your criteria');
      }
    },
    [originalLabs, rowsPerPage]
  );

  const onChangeCurrentPage = useCallback((event, page) => {
    setCurrentPage(page);
  }, []);

  const onChangeRowsPerPage = useCallback((event) => {
    setCurrentPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageContainer>
      <Header>
        <H1>Lab Configuration Dashboard</H1>
      </Header>
      <MainContent>
        <SearchContainer>
          <InputContainer>
            <SearchIcon />
            <TextInput
              value={searchValue}
              onChange={searchValueOnChangeHandler}
              placeholder="Type in the lab number or name you are looking for here!"
              inputProps={{ 'data-testid': 'searchBarTextInput' }}
            />
          </InputContainer>
          <TextContainer>
            <P>
              Please take a moment to provide feedback to{' '}
              <Link
                href="https://docs.google.com/forms/d/e/1FAIpQLSckW0yMkJEeYOm5x9LkWVXma-FQbTwCSE2ByumGGO2sUMJrBA/viewform?usp=sf_link"
                underline="hover"
              >
                this survey
              </Link>{' '}
              after you are finished with utilizing the dashboard!
            </P>
            <P>
              This is very much in the first stage of development to essentially
              just gather how we can best serve you!
            </P>
          </TextContainer>
        </SearchContainer>
        <TableContainer component={StyledPaper}>
          {errorMessage.length > 0 ? (
            <P>{errorMessage}</P>
          ) : (
            <DashboardTable
              rowsPerPage={rowsPerPage}
              searchedLabs={searchedLabs}
              page={currentPage}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangePage={onChangeCurrentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </MainContent>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: rgba(74, 92, 105, 100);
`;

const MainContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(239, 239, 244, 100);
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 1rem 2.5rem 1rem;
`;

const TextContainer = styled.div`
  border-radius: 10px;
  padding: 0.5rem;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  margin: 2rem 0;
  width: 80%;
  max-width: 40rem;
`;

const StyledPaper = styledMaterialUI(Paper)({
  margin: '2rem',
  width: '100%',
  maxWidth: '46rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignContent: 'center',
});

export default DashboardPage;
